import { CurrencyAmount, JSBI, Token, Trade } from "@pancakeswap-libs/sdk-v2";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { ArrowDown } from "react-feather";
import {
    CardBody,
    Button,
    SwapVertIcon,
    IconButton,
    Text,
} from "crox-uikit2.0";
import styled, { ThemeContext } from "styled-components";
import AddressInputPanel from "components/AddressInputPanel";
import Card, { GreyCard } from "components/Card";
import { AutoColumn } from "components/Column";
import ConfirmSwapModal from "components/swap/ConfirmSwapModal";
import CurrencyInputPanel from "components/CurrencyInputPanel";
import CardNav from "components/CardNav";
import { AutoRow, RowBetween } from "components/Row";
import AdvancedSwapDetailsDropdown from "components/swap/AdvancedSwapDetailsDropdown";
import BetterTradeLink from "components/swap/BetterTradeLink";
import confirmPriceImpactWithoutFee from "components/swap/confirmPriceImpactWithoutFee";
import {
    ArrowWrapper,
    BottomGrouping,
    SwapCallbackError,
    Wrapper,
} from "components/swap/styleds";
import useMediaQuery from "@mui/material/useMediaQuery";
import TradePrice from "components/swap/TradePrice";
import TokenWarningModal from "components/TokenWarningModal";
import SyrupWarningModal from "components/SyrupWarningModal";
import ProgressSteps from "components/ProgressSteps";

import {
    BETTER_TRADE_LINK_THRESHOLD,
    INITIAL_ALLOWED_SLIPPAGE,
} from "constants/index";
import { isTradeBetter } from "data/V1";
import { useActiveWeb3React } from "hooks";
import { useCurrency } from "hooks/Tokens";
import {
    ApprovalState,
    useApproveCallbackFromTrade,
} from "hooks/useApproveCallback";
import { useSwapCallback } from "hooks/useSwapCallback";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import useToggledVersion, { Version } from "hooks/useToggledVersion";
import useWrapCallback, { WrapType } from "hooks/useWrapCallback";
import { Field } from "state/swap/actions";
import {
    useDefaultsFromURLSearch,
    useDerivedSwapInfo,
    useSwapActionHandlers,
    useSwapState,
} from "state/swap/hooks";
import {
    useExpertModeManager,
    useUserDeadline,
    useUserSlippageTolerance,
} from "state/user/hooks";
import { LinkStyledButton, TYPE } from "components/Shared";
import { maxAmountSpend } from "utils/maxAmountSpend";
import { computeTradePriceBreakdown, warningSeverity } from "utils/prices";
import Loader from "components/Loader";
import { TranslateString } from "utils/translateTextHelpers";
import PageHeader from "components/PageHeader";
import ConnectWalletButton from "components/ConnectWalletButton";
import CountDown from "components/CountDown";
import { motion } from "framer-motion"
import AppBody from "../AppBody";
import "./swap.css"
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper.min.css";

SwiperCore.use([Navigation, Autoplay]);

const ArrowGroup = styled.div`
  background-color: #27262c;
  border-radius: 50%;
  padding: 2px;
`

const SwiperGroup = styled.div`
  max-width: 600px;
  width: 90%;
  margin-top: 2%;
  & .swiper-button-prev:after,
  & .swiper-button-next:after {
    opacity: 0.5 !important;
    font-size: 20px !important;
    color: white !important;
  }
`

const CogIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><linearGradient id='gradient'><stop offset='10%' stopColor='%23F00' /><stop offset='90%' stopColor='%23fcc' /> </linearGradient><rect fill='url(%23gradient)' x='0' y='0' width='100%' height='100%' /></svg>
)

const { main: Main } = TYPE;

const Swap = () => {
    const loadedUrlParams = useDefaultsFromURLSearch();

    // token warning stuff
    const [loadedInputCurrency, loadedOutputCurrency] = [
        useCurrency(loadedUrlParams?.inputCurrencyId),
        useCurrency(loadedUrlParams?.outputCurrencyId),
    ];
    const [dismissTokenWarning, setDismissTokenWarning] =
        useState<boolean>(false);
    const [isSyrup, setIsSyrup] = useState<boolean>(false);
    const [syrupTransactionType, setSyrupTransactionType] =
        useState<string>("");
    const urlLoadedTokens: Token[] = useMemo(
        () =>
            [loadedInputCurrency, loadedOutputCurrency]?.filter(
                (c): c is Token => c instanceof Token
            ) ?? [],
        [loadedInputCurrency, loadedOutputCurrency]
    );
    const [altTokens, setAltTokens] = useState<Token[]>([]);
    useEffect(() => {
        setAltTokens(urlLoadedTokens.filter(token => (token.address !== '0x2c094F5A7D1146BB93850f629501eB749f6Ed491' && token.address !== "0x55d398326f99059fF775485246999027B3197955")));
    }, [urlLoadedTokens]);
    const handleConfirmTokenWarning = useCallback(() => {
        setDismissTokenWarning(true);
    }, []);

    const handleConfirmSyrupWarning = useCallback(() => {
        setIsSyrup(false);
        setSyrupTransactionType("");
    }, []);

    const { account } = useActiveWeb3React();
    const theme = useContext(ThemeContext);

    const [isExpertMode] = useExpertModeManager();

    // get custom setting values for user
    const [deadline] = useUserDeadline();
    const [allowedSlippage] = useUserSlippageTolerance();

    // swap state
    const { independentField, typedValue, recipient } = useSwapState();
    const {
        v1Trade,
        v2Trade,
        currencyBalances,
        parsedAmount,
        currencies,
        inputError: swapInputError,
    } = useDerivedSwapInfo();
    const {
        wrapType,
        execute: onWrap,
        inputError: wrapInputError,
    } = useWrapCallback(
        currencies[Field.INPUT],
        currencies[Field.OUTPUT],
        typedValue
    );
    const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
    //   const { address: recipientAddress } = useENSAddress(recipient)
    const toggledVersion = useToggledVersion();
    const trade = showWrap
        ? undefined
        : {
            [Version.v1]: v1Trade,
            [Version.v2]: v2Trade,
        }[toggledVersion];

    const betterTradeLinkVersion: Version | undefined =
        toggledVersion === Version.v2 &&
            isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
            ? Version.v1
            : toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
                ? Version.v2
                : undefined;

    const parsedAmounts = showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
        }
        : {
            [Field.INPUT]:
                independentField === Field.INPUT
                    ? parsedAmount
                    : trade?.inputAmount,
            [Field.OUTPUT]:
                independentField === Field.OUTPUT
                    ? parsedAmount
                    : trade?.outputAmount,
        };

    const {
        onSwitchTokens,
        onCurrencySelection,
        onUserInput,
        onChangeRecipient,
    } = useSwapActionHandlers();
    const isValid = !swapInputError;
    const dependentField: Field =
        independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

    const handleTypeInput = useCallback(
        (value: string) => {
            onUserInput(Field.INPUT, value);
        },
        [onUserInput]
    );
    const handleTypeOutput = useCallback(
        (value: string) => {
            onUserInput(Field.OUTPUT, value);
        },
        [onUserInput]
    );

    // modal and loading
    const [
        {
            showConfirm,
            tradeToConfirm,
            swapErrorMessage,
            attemptingTxn,
            txHash,
        },
        setSwapState,
    ] = useState<{
        showConfirm: boolean;
        tradeToConfirm: Trade | undefined;
        attemptingTxn: boolean;
        swapErrorMessage: string | undefined;
        txHash: string | undefined;
    }>({
        showConfirm: false,
        tradeToConfirm: undefined,
        attemptingTxn: false,
        swapErrorMessage: undefined,
        txHash: undefined,
    });

    const formattedAmounts = {
        [independentField]: typedValue,
        [dependentField]: showWrap
            ? parsedAmounts[independentField]?.toExact() ?? ""
            : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
    };

    const route = trade?.route;
    const userHasSpecifiedInputOutput = Boolean(
        currencies[Field.INPUT] &&
        currencies[Field.OUTPUT] &&
        parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
    );
    const noRoute = !route;

    // check whether the user has approved the router on the input token
    const [approval, approveCallback] = useApproveCallbackFromTrade(
        trade,
        allowedSlippage
    );

    // check if user has gone through approval process, used to show two step buttons, reset on token change
    const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

    // mark when a user has submitted an approval, reset onTokenSelection for input field
    useEffect(() => {
        if (approval === ApprovalState.PENDING) {
            setApprovalSubmitted(true);
        }
    }, [approval, approvalSubmitted]);

    const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
        currencyBalances[Field.INPUT]
    );
    const atMaxAmountInput = Boolean(
        maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
    );

    // the callback to execute the swap
    const { callback: swapCallback, error: swapCallbackError } =
        useSwapCallback(trade, allowedSlippage, deadline, recipient);

    const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

    const handleSwap = useCallback(() => {
        if (
            priceImpactWithoutFee &&
            !confirmPriceImpactWithoutFee(priceImpactWithoutFee)
        ) {
            return;
        }
        if (!swapCallback) {
            return;
        }
        setSwapState((prevState) => ({
            ...prevState,
            attemptingTxn: true,
            swapErrorMessage: undefined,
            txHash: undefined,
        }));
        swapCallback()
            .then((hash) => {
                setSwapState((prevState) => ({
                    ...prevState,
                    attemptingTxn: false,
                    swapErrorMessage: undefined,
                    txHash: hash,
                }));
            })
            .catch((error) => {
                setSwapState((prevState) => ({
                    ...prevState,
                    attemptingTxn: false,
                    swapErrorMessage: error.message,
                    txHash: undefined,
                }));
            });
    }, [priceImpactWithoutFee, swapCallback, setSwapState]);

    // errors
    const [showInverted, setShowInverted] = useState<boolean>(false);

    // warnings on slippage
    const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

    // show approve flow when: no error on inputs, not approved or pending, or approved in current session
    // never show if price impact is above threshold in non expert mode
    const showApproveFlow =
        !swapInputError &&
        (approval === ApprovalState.NOT_APPROVED ||
            approval === ApprovalState.PENDING ||
            (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
        !(priceImpactSeverity > 3 && !isExpertMode);

    const handleConfirmDismiss = useCallback(() => {
        setSwapState((prevState) => ({ ...prevState, showConfirm: false }));

        // if there was a tx hash, we want to clear the input
        if (txHash) {
            onUserInput(Field.INPUT, "");
        }
    }, [onUserInput, txHash, setSwapState]);

    const handleAcceptChanges = useCallback(() => {
        setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }));
    }, [trade]);

    // This will check to see if the user has selected Syrup to either buy or sell.
    // If so, they will be alerted with a warning message.
    const checkForSyrup = useCallback(
        (selected: string, purchaseType: string) => {
            if (selected === "syrup") {
                setIsSyrup(true);
                setSyrupTransactionType(purchaseType);
            }
        },
        [setIsSyrup, setSyrupTransactionType]
    );

    const handleInputSelect = useCallback(
        (inputCurrency) => {
            setApprovalSubmitted(false); // reset 2 step UI for approvals
            onCurrencySelection(Field.INPUT, inputCurrency);
            if (inputCurrency.symbol.toLowerCase() === "syrup") {
                checkForSyrup(inputCurrency.symbol.toLowerCase(), "Selling");
            }
        },
        [onCurrencySelection, setApprovalSubmitted, checkForSyrup]
    );

    const handleMaxInput = useCallback(() => {
        if (maxAmountInput) {
            onUserInput(Field.INPUT, maxAmountInput.toExact());
        }
    }, [maxAmountInput, onUserInput]);

    const handleOutputSelect = useCallback(
        (outputCurrency) => {
            onCurrencySelection(Field.OUTPUT, outputCurrency);
            if (outputCurrency.symbol.toLowerCase() === "syrup") {
                checkForSyrup(outputCurrency.symbol.toLowerCase(), "Buying");
            }
        },
        [onCurrencySelection, checkForSyrup]
    );
    const [isHover, setHover] = useState(false)
    const ismobile = useMediaQuery("(max-width: 600px)")

    return (
        <>
            <Text fontSize={ismobile ? "24px" : "30px"} bold className="header_text" color="#0dbad7">Swap, Stake & Farm</Text>
            <Text fontSize={ismobile ? "14px" : "16px"} style={{ textTransform: 'uppercase', fontStyle: 'italic' }} color="#b1afb8">The Next-Generation Yield Farm</Text>

            <TokenWarningModal
                isOpen={altTokens.length > 0 && !dismissTokenWarning}
                tokens={altTokens}
                onConfirm={handleConfirmTokenWarning}
            />
            <SyrupWarningModal
                isOpen={isSyrup}
                transactionType={syrupTransactionType}
                onConfirm={handleConfirmSyrupWarning}
            />
            <CountDown timestamp={1618708740000} />
            <SwiperGroup>
                <Swiper
                    navigation
                    loop
                    className="mySwiper"
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                >
                    <SwiperSlide>
                        <img
                            src={ismobile ? "/cross_chain_mobile.png" : "/cross_chain.png"}
                            alt="cross_chain_banner"
                            style={{
                                borderRadius: "10px",
                                display: "block",
                                height: "100%",
                                margin: "auto",
                                width: "100%"
                            }}
                            onClick={() =>
                                window.open("https://docs.croxswap.com/products/nfts", "_blank")
                            }
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={ismobile ? "/dual_farming_mobile.png" : "/dual_farming.png"}
                            alt="dual_farming"
                            style={{
                                borderRadius: "10px",
                                display: "block",
                                height: "100%",
                                margin: "auto",
                                width: "100%"
                            }}
                            onClick={() =>
                                window.open("https://bridge.croxswap.com/", "_blank")
                            }
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={ismobile ? "/farmer_banner_mobile.png" : "/farmer_banner.png"}
                            alt="farmer_banner"
                            style={{
                                borderRadius: "10px",
                                display: "block",
                                height: "100%",
                                margin: "auto",
                                width: "100%"
                            }}
                            onClick={() =>
                                window.open("https://referral.croxswap.com/", "_blank")
                            }
                        />
                    </SwiperSlide>
                </Swiper>
            </SwiperGroup>
            <AppBody title="">
                <Wrapper id="swap-page">
                    <ConfirmSwapModal
                        trade={trade}
                        isOpen={showConfirm}
                        originalTrade={tradeToConfirm}
                        onAcceptChanges={handleAcceptChanges}
                        attemptingTxn={attemptingTxn}
                        txHash={txHash}
                        recipient={recipient}
                        allowedSlippage={allowedSlippage}
                        onConfirm={handleSwap}
                        swapErrorMessage={swapErrorMessage}
                        onDismiss={handleConfirmDismiss}
                    />
                    <PageHeader
                        title="Exchange"
                        description="Trade tokens in an instant"
                    />
                    <CardBody style={{ padding: "10px 15px" }}>
                        <AutoColumn gap="tiny">
                            <CurrencyInputPanel
                                label={
                                    independentField === Field.OUTPUT &&
                                        !showWrap &&
                                        trade
                                        ? "From (estimated)"
                                        : "From"
                                }
                                value={formattedAmounts[Field.INPUT]}
                                showMaxButton={!atMaxAmountInput}
                                currency={currencies[Field.INPUT]}
                                onUserInput={handleTypeInput}
                                onMax={handleMaxInput}
                                onCurrencySelect={handleInputSelect}
                                otherCurrency={currencies[Field.OUTPUT]}
                                id="swap-currency-input"
                            />
                            <AutoColumn justify="space-between" style={{ zIndex: 3, marginTop: "-11px", marginBottom: "-11px" }}>
                                <AutoRow
                                    justify={
                                        isExpertMode
                                            ? "space-between"
                                            : "left"
                                    }
                                    style={{ padding: "0 1rem" }}
                                >
                                    <motion.div
                                        transition={{ duration: 1 }}
                                        drag
                                        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                                        whileHover={{ opacity: 1 }}
                                        whileTap={{
                                            opacity: 1,
                                            scale: 1.05,
                                        }}
                                        dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                                        dragElastic={0.5}
                                        whileDrag={{ scale: 1 }}
                                    >
                                        <motion.div
                                            animate={{
                                                scale: isHover ? 1 : 1,
                                                rotate: isHover ? 360 : 0,
                                            }}
                                            onHoverStart={() => setHover(true)}
                                            onHoverEnd={() => setHover(false)}
                                        >
                                            <ArrowGroup>
                                                <ArrowWrapper clickable>
                                                    <IconButton
                                                        variant="primary"
                                                        onClick={() => {
                                                            setApprovalSubmitted(false); // reset 2 step UI for approvals
                                                            onSwitchTokens();
                                                        }}
                                                        style={{ borderRadius: "50%", width: "55px", height: "55px" }}
                                                        size="md"
                                                    >
                                                        <SwapVertIcon
                                                            color="white"
                                                            width="50px"
                                                        />
                                                    </IconButton>
                                                </ArrowWrapper>
                                            </ArrowGroup>
                                        </motion.div>
                                    </motion.div>
                                    {recipient === null &&
                                        !showWrap &&
                                        isExpertMode ? (
                                        <LinkStyledButton
                                            id="add-recipient-button"
                                            onClick={() =>
                                                onChangeRecipient("")
                                            }
                                        >
                                            + Add a send (optional)
                                        </LinkStyledButton>
                                    ) : null}
                                </AutoRow>
                            </AutoColumn>
                            <CurrencyInputPanel
                                value={formattedAmounts[Field.OUTPUT]}
                                onUserInput={handleTypeOutput}
                                label={
                                    independentField === Field.INPUT &&
                                        !showWrap &&
                                        trade
                                        ? "To (estimated)"
                                        : "To"
                                }
                                showMaxButton={false}
                                currency={currencies[Field.OUTPUT]}
                                onCurrencySelect={handleOutputSelect}
                                otherCurrency={currencies[Field.INPUT]}
                                id="swap-currency-output"
                            />

                            {recipient !== null && !showWrap ? (
                                <>
                                    <AutoRow
                                        justify="space-between"
                                        style={{ padding: "0 1rem" }}
                                    >
                                        <ArrowWrapper clickable={false}>
                                            <ArrowDown
                                                size="16"
                                                color={theme.colors.textSubtle}
                                            />
                                        </ArrowWrapper>
                                        <LinkStyledButton
                                            id="remove-recipient-button"
                                            onClick={() =>
                                                onChangeRecipient(null)
                                            }
                                        >
                                            - Remove send
                                        </LinkStyledButton>
                                    </AutoRow>
                                    <AddressInputPanel
                                        id="recipient"
                                        value={recipient}
                                        onChange={onChangeRecipient}
                                    />
                                </>
                            ) : null}

                            {showWrap ? null : (
                                <Card
                                    padding=".25rem .75rem 0 .75rem"
                                    borderRadius="20px"
                                >
                                    <AutoColumn gap="4px">
                                        {Boolean(trade) && (
                                            <RowBetween align="center">
                                                <Text fontSize="14px" color="white">
                                                    Price
                                                </Text>
                                                <TradePrice
                                                    price={
                                                        trade?.executionPrice
                                                    }
                                                    showInverted={showInverted}
                                                    setShowInverted={
                                                        setShowInverted
                                                    }
                                                />
                                            </RowBetween>
                                        )}
                                        {allowedSlippage !==
                                            INITIAL_ALLOWED_SLIPPAGE && (
                                                <RowBetween align="center">
                                                    <Text fontSize="14px">
                                                        Slippage Tolerance
                                                    </Text>
                                                    <Text fontSize="14px">
                                                        {allowedSlippage / 100}%
                                                    </Text>
                                                </RowBetween>
                                            )}
                                    </AutoColumn>
                                </Card>
                            )}
                        </AutoColumn>
                        <BottomGrouping>
                            {!account ? (
                                <ConnectWalletButton fullWidth style={{ padding: "30px", borderRadius: "5px" }} />
                            ) : showWrap ? (
                                <Button
                                    disabled={Boolean(wrapInputError)}
                                    onClick={onWrap}
                                    fullWidth
                                    style={{ padding: "30px", borderRadius: "5px" }}
                                >
                                    {wrapInputError ??
                                        (wrapType === WrapType.WRAP
                                            ? "Wrap"
                                            : wrapType === WrapType.UNWRAP
                                                ? "Unwrap"
                                                : null)}
                                </Button>
                            ) : noRoute && userHasSpecifiedInputOutput ? (
                                <GreyCard style={{ textAlign: "center" }}>
                                    <Main mb="4px">
                                        Insufficient liquidity for this trade.
                                    </Main>
                                </GreyCard>
                            ) : showApproveFlow ? (
                                <RowBetween>
                                    <Button
                                        onClick={approveCallback}
                                        disabled={
                                            approval !==
                                            ApprovalState.NOT_APPROVED ||
                                            approvalSubmitted
                                        }
                                        style={{ width: "48%", padding: "30px", borderRadius: "5px" }}
                                        variant={
                                            approval === ApprovalState.APPROVED
                                                ? "success"
                                                : "primary"
                                        }
                                    >
                                        {approval === ApprovalState.PENDING ? (
                                            <AutoRow gap="6px" justify="center">
                                                Approving{" "}
                                                <Loader stroke="white" />
                                            </AutoRow>
                                        ) : approvalSubmitted &&
                                            approval ===
                                            ApprovalState.APPROVED ? (
                                            "Approved"
                                        ) : (
                                            `Approve ${currencies[Field.INPUT]?.symbol
                                            }`
                                        )}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            if (isExpertMode) {
                                                handleSwap();
                                            } else {
                                                setSwapState({
                                                    tradeToConfirm: trade,
                                                    attemptingTxn: false,
                                                    swapErrorMessage: undefined,
                                                    showConfirm: true,
                                                    txHash: undefined,
                                                });
                                            }
                                        }}
                                        style={{ width: "48%", padding: "30px", borderRadius: "5px" }}
                                        id="swap-button"
                                        disabled={
                                            !isValid ||
                                            approval !==
                                            ApprovalState.APPROVED ||
                                            (priceImpactSeverity > 3 &&
                                                !isExpertMode)
                                        }
                                        variant={
                                            isValid && priceImpactSeverity > 2
                                                ? "danger"
                                                : "primary"
                                        }
                                    >
                                        {priceImpactSeverity > 3 &&
                                            !isExpertMode
                                            ? `Price Impact High`
                                            : `Swap${priceImpactSeverity > 2
                                                ? " Anyway"
                                                : ""
                                            }`}
                                    </Button>
                                </RowBetween>
                            ) : (
                                <Button
                                    onClick={() => {
                                        if (isExpertMode) {
                                            handleSwap();
                                        } else {
                                            setSwapState({
                                                tradeToConfirm: trade,
                                                attemptingTxn: false,
                                                swapErrorMessage: undefined,
                                                showConfirm: true,
                                                txHash: undefined,
                                            });
                                        }
                                    }}
                                    id="swap-button"
                                    disabled={
                                        !isValid ||
                                        (priceImpactSeverity > 3 &&
                                            !isExpertMode) ||
                                        !!swapCallbackError
                                    }
                                    variant={
                                        isValid &&
                                            priceImpactSeverity > 2 &&
                                            !swapCallbackError
                                            ? "danger"
                                            : "primary"
                                    }
                                    style={{ padding: "30px", borderRadius: "5px" }}
                                    fullWidth
                                >
                                    {swapInputError ||
                                        (priceImpactSeverity > 3 &&
                                            !isExpertMode
                                            ? `Price Impact Too High`
                                            : `Swap${priceImpactSeverity > 2
                                                ? " Anyway"
                                                : ""
                                            }`)}
                                </Button>
                            )}
                            {showApproveFlow && (
                                <ProgressSteps
                                    steps={[
                                        approval === ApprovalState.APPROVED,
                                    ]}
                                />
                            )}
                            {isExpertMode && swapErrorMessage ? (
                                <SwapCallbackError error={swapErrorMessage} />
                            ) : null}
                            {betterTradeLinkVersion && (
                                <BetterTradeLink
                                    version={betterTradeLinkVersion}
                                />
                            )}
                        </BottomGrouping>
                    </CardBody>
                </Wrapper>
            </AppBody>
            <AdvancedSwapDetailsDropdown trade={trade} />
        </>
    );
};

export default Swap;
